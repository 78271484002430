var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "full",
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Nilai Gizi',
        href: '/admin/glossary',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh'],
      "display": "flex",
      "flex-direction": "column",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "align-items": "center",
      "margin-bottom": "30px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" Manajemen Nilai Gizi ")]), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'admin.glossary-new'
        });
      }
    }
  }, [_vm._v(" Tambah Nilai Gizi ")])], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "padding-inline": "20px"
    }
  }, [_c('GlossaryContainer', {
    attrs: {
      "breakpoint": _vm.currentBreakpoint
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }