<template>
  <c-box 
    w="full"
    flex-grow="1"
  >
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[
        {
          label: 'Manajemen Nilai Gizi',
          href: '/admin/glossary',
          isCurrent: true
        },
      ]"
    />
    <c-box
      width="100%"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
      display="flex"
      flex-direction="column"
      align-items="center"
    >
      <c-flex
        width="100%"
        justify-content="space-between"
        align-items="center"
        margin-bottom="30px"
      >
        <BaseText
          size-mobile="20px"
          size-desktop="28px"
          white-space="nowrap"
        >
          Manajemen Nilai Gizi
        </BaseText>
        
        <BaseButton
          :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="white"
          @click="$router.push({ name:'admin.glossary-new' })"
        >
          Tambah Nilai Gizi
        </BaseButton>
      </c-flex>

      <c-box
        width="100%"
        padding-inline="20px"
      >
        <GlossaryContainer
          :breakpoint="currentBreakpoint"
        />
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import GlossaryContainer from '@/components/glossary/glossary-container.vue'
import mixinsCheckBreakpoints from '@/mixins/mixins-check-breakpoints'

export default {
  name: 'SUGlossaryList',
  components: {
    BreadcrumbPath,
    BaseText,
    BaseButton,
    GlossaryContainer,
  },
  mixins: [
    mixinsCheckBreakpoints(),
  ],
  computed: {
    breadcrumbItems() {
      return [
        {
          label: 'Manajemen Nilai Gizi',
          href: null,
          isCurrent: true,
        },
      ]
    },
  },
}
</script>
